import {Box, BoxProps, SxProps} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {theme} from 'src/constants/theme';

interface SpinnerProps extends BoxProps {
  fullScreen?: boolean;
  size?: string | number;
  color?: string;
}

const fullScreenStyles: SxProps<typeof theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  '> *': {
    marginBottom: 6,
  },
};

export function Spinner({fullScreen = false, size = 40, sx, color, ...rest}: SpinnerProps): JSX.Element {
  return (
    <Box
      sx={[
        {
          textAlign: 'center',
          '.MuiCircularProgress-root': {
            verticalAlign: 'middle',
            ...(color && {color}),
          },
        },
        fullScreen && fullScreenStyles,
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
      data-testid='loading-spinner'
    >
      <CircularProgress disableShrink size={size} />
    </Box>
  );
}
