import {CheckCircleOutlined, ErrorOutlined, InfoOutlined, WarningOutlined} from '@mui/icons-material';
import {AlertColor, AlertProps, AlertTitle, Box, Alert as MuiAlert} from '@mui/material';

export interface StyledAlertProps extends AlertProps {
  title?: string;
  /**
   * @default 'info'
   */
  severity?: AlertColor;
  msgCreator?: () => any;
}

const iconMapping = {
  info: <InfoOutlined sx={{color: 'info.main'}} />,
  success: <CheckCircleOutlined sx={{color: 'success.main'}} />,
  warning: <WarningOutlined sx={{color: 'warning.main'}} />,
  error: <ErrorOutlined sx={{color: 'error.main'}} />,
};
export function Alert({title, msgCreator = () => '', severity = 'info', sx, ...rest}: StyledAlertProps): JSX.Element {
  return (
    <MuiAlert
      severity={severity}
      icon={iconMapping[severity]}
      sx={[
        {
          marginY: 2,
          paddingX: 2,
          paddingY: 1,
          alignItems: 'center',
          border: '1px solid',
          borderColor: `${severity}.main`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {title && <AlertTitle sx={{color: 'maddox.black80', fontWeight: 'bold'}}>{title}</AlertTitle>}
      <Box sx={{color: 'maddox.black80', whiteSpace: 'pre-line'}}>{msgCreator()}</Box>
    </MuiAlert>
  );
}
