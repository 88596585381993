import {Avatar, AvatarProps} from '@mui/material';
import React from 'react';

import {Tooltip, TooltipProps} from './Tooltip';

export interface InitialsAvatarProps extends AvatarProps {
  name: string;
  backgroundColor?: string;
  /**
   * @default 'large'
   */
  size?: 'small' | 'large' | number;
  /**
   * @default false
   */
  withTooltip?: boolean;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
}

function getInitials(name: string): string {
  if (name.length === 0) {
    return '';
  }
  if (name.length === 1) {
    return name[0];
  }
  const nameParts: string[] = name.trim().split(' ');
  if (nameParts.length === 1) {
    return nameParts[0].slice(0, 2);
  }
  return nameParts.reduce((acc, namePart) => acc + namePart[0], '').slice(0, 2);
}

export const InitialsAvatar = React.forwardRef<HTMLDivElement, InitialsAvatarProps>(function InitialsAvatar(
  {name, backgroundColor = 'secondary.main', size = 'large', sx = [], withTooltip = false, tooltipProps = {}, ...rest},
  ref,
) {
  let fontSize: number, pxSize: number;
  if (size === 'small') {
    fontSize = 10;
    pxSize = 24;
  } else if (size === 'large' || size === undefined) {
    fontSize = 14;
    pxSize = 36;
  } else {
    fontSize = size;
    pxSize = size;
  }

  const avatarComponent = (
    <Avatar
      ref={ref}
      sx={[
        {
          width: pxSize,
          height: pxSize,
          lineHeight: `${pxSize}px`,
          fontSize,
          textTransform: 'uppercase',
          bgcolor: backgroundColor,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {getInitials(name)}
    </Avatar>
  );

  if (withTooltip) {
    return (
      <Tooltip title={name} arrow {...tooltipProps}>
        {avatarComponent}
      </Tooltip>
    );
  } else {
    return avatarComponent;
  }
});
