/**
 * A wrapper around the MUI Link component that's using the Next.js Link.
 *
 * Component source based on: https://github.com/mui/material-ui/blob/HEAD/examples/nextjs-with-typescript/src/Link.tsx
 * Docs: https://mui.com/material-ui/guides/routing/#next-js
 */

import MuiLink, {LinkProps as MuiLinkProps} from '@mui/material/Link';
import NextLink, {LinkProps as NextLinkProps} from 'next/link';
import * as React from 'react';

export const Link = React.forwardRef<HTMLAnchorElement, NextLinkProps & MuiLinkProps>(function Link(props, ref) {
  return <MuiLink component={NextLink} ref={ref} {...props} />;
});
