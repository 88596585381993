import {IconButton} from '@mui/material';

import {useGoBack} from '~hooks/misc.hooks';

import {raisedUIShadow} from 'src/constants/theme';
import {BackIcon} from 'src/icons';

export function BackButton() {
  const goBack = useGoBack();

  return (
    <IconButton
      data-testid='back-button'
      onClick={goBack}
      sx={{
        borderRadius: 1,
        padding: 0,
        color: 'maddox.globalblack',
        '&:hover': {
          color: 'primary.light',
          backgroundColor: 'maddox.hover',
          boxShadow: raisedUIShadow,
        },
      }}
    >
      <BackIcon />
    </IconButton>
  );
}
