import {List, ListItem, ListItemText} from '@mui/material';
import {useTranslation} from 'i18n';

import {Project} from '~redux/types/user';
import {selectCustomer, selectProjects, selectUserEmail, selectUsername} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {StatusWrapper} from '../common/StatusWrapper';

const AccountListItem = ({primary, secondary}: {primary: string; secondary?: string | number}): JSX.Element => {
  return (
    <ListItem>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{fontSize: 16}}
        secondaryTypographyProps={{fontSize: 14, fontWeight: 600, color: 'maddox.black80'}}
      />
    </ListItem>
  );
};

export function AccountTab(): JSX.Element {
  const {t} = useTranslation('user');
  const userEmail = useAppSelector(selectUserEmail);
  const userName = useAppSelector(selectUsername);
  const customer = useAppSelector(selectCustomer);
  const projects = useAppSelector(selectProjects);
  return (
    <StatusWrapper
      isLoading={!userEmail}
      creator={(): JSX.Element => (
        <List>
          <AccountListItem primary={t('name')} secondary={userName} />
          <AccountListItem primary={t('emailAddress')} secondary={userEmail} />
          <AccountListItem primary={t('userId')} secondary={customer} />
          <AccountListItem primary={t('numberOfProjects')} secondary={projects?.length} />
          <AccountListItem
            primary={t('numberOfInspectionPoints')}
            secondary={projects
              ?.map((project: Project) => project.devices.length)
              .reduce((a: number, b: number): number => a + b, 0)}
          />
        </List>
      )}
    />
  );
}
