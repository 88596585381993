import {Box, Typography} from '@mui/material';
import {useRouter} from 'next/router';

import {BreadcrumbLayout, StyledBreadcrumbsBox} from '~components/common/BreadcrumbLayout';
import {BreadCrumbElement} from '~components/header/breadcrumbs/BreadCrumbElement';
import {useBreadcrumbs} from '~components/header/breadcrumbs/useBreadcrumbs';

export function Breadcrumbs({titleActions}: {titleActions?: React.ReactNode}) {
  const router = useRouter();
  const breadcrumbs = useBreadcrumbs(router.pathname);

  const breadcrumbLinks = breadcrumbs.slice(0, -1);
  const pageTitle = breadcrumbs.at(-1)?.name;

  return (
    <StyledBreadcrumbsBox>
      <BreadcrumbLayout cursor='pointer'>
        {breadcrumbLinks.map((breadcrumb, index: number) => (
          <BreadCrumbElement
            data-testid={`breadcrumb-${index}`}
            key={`${breadcrumb.name}-${index}`}
            breadcrumb={breadcrumb}
          />
        ))}
      </BreadcrumbLayout>
      <Box sx={{gridArea: 'title', alignSelf: 'center', display: 'flex', alignItems: 'center'}}>
        <Typography variant='h2' component='h1'>
          {pageTitle}
        </Typography>
        {titleActions}
      </Box>
    </StyledBreadcrumbsBox>
  );
}
