import {useTranslation} from 'i18n';
import React from 'react';

import {Alert} from './Alert';

export function NoContentAlert({text, title}: {text?: string; title?: string}): JSX.Element {
  const {t} = useTranslation('error');
  return (
    <Alert
      severity='info'
      title={title ? title : t('noData')}
      msgCreator={() => (text ? text : t('noDataForFilter'))}
    />
  );
}
