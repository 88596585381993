import {Typography} from '@mui/material';
import {useTranslation} from 'i18n';

import {LayoutContent} from '~components/_layout/layoutComponents';

export function NoImagesLayout() {
  const {t} = useTranslation('error');

  return (
    <LayoutContent
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src='/images/no-images.svg' alt='No images' width={300} />
      <Typography variant='h1' sx={{textAlign: 'center', marginBottom: 2}}>
        {t('noImages.title')}
      </Typography>
      <Typography sx={{textAlign: 'center', marginBottom: 2}}>{t('noImages.description')}</Typography>
    </LayoutContent>
  );
}
