import {List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, SvgIcon} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import {i18n, useTranslation} from 'i18n';

import {useUserPreferencesMutation} from '~api/user.mutations';
import {selectLandingPage, selectUserEmail} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {styled} from 'src/constants/theme';
import {FlagDeIcon, FlagEnIcon} from 'src/icons';
import {StatusWrapper} from '../common/StatusWrapper';

const LanguageItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  flexGrow: 1,
});

const MenuProps = {
  style: {
    padding: 32,
  },
  PaperProps: {
    style: {
      maxHeight: 264,
      width: 370,
    },
  },
};

const LanguageIcon = ({language}: {language: string}): JSX.Element => {
  const languageIdToLanguage: {[key: string]: string} = {'de-DE': 'Deutsch', en: 'English'};
  const icon = () => {
    switch (language) {
      case 'de-DE':
        return FlagDeIcon;
      case 'en':
        return FlagEnIcon;
      default:
    }
  };
  return (
    <LanguageItem data-testid={'languageitem-' + language}>
      <SvgIcon component={icon()} name={language} height={20} />
      {languageIdToLanguage[language]}
    </LanguageItem>
  );
};

const PersonalListItem = ({primary, secondary}: {primary: string; secondary?: string | number}): JSX.Element => {
  return (
    <ListItem>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{fontSize: 16}}
        secondaryTypographyProps={{fontSize: 14, fontWeight: 600, color: 'maddox.black80'}}
      />
    </ListItem>
  );
};

export function PersonalTab(): JSX.Element {
  const {t} = useTranslation(['user', 'mainMenu']);
  const userEmail = useAppSelector(selectUserEmail);
  const userLandingPage = useAppSelector(selectLandingPage);
  const setUserPreferences = useUserPreferencesMutation();

  const updateLanguage = async (event: SelectChangeEvent): Promise<void> => {
    await i18n.changeLanguage(event.target.value);
    Sentry.setTag('language', event.target.value);
    localStorage.setItem('i18nextLng', event.target.value);
  };

  const updateStartPage = async (event: SelectChangeEvent): Promise<void> => {
    setUserPreferences.mutate(event.target.value);
    Sentry.setTag('startPage', event.target.value);
    localStorage.setItem('startPage', event.target.value);
  };

  return (
    <StatusWrapper
      isLoading={!userEmail}
      creator={(): JSX.Element => (
        <List>
          <PersonalListItem primary={t('changeLanguage')} />
          <ListItem sx={{marginTop: -2}}>
            <Select
              data-testid='language-select'
              size='small'
              autoWidth
              value={i18n.language}
              onChange={updateLanguage}
              sx={{width: '100%'}}
              MenuProps={MenuProps}
            >
              <MenuItem value='de-DE'>
                <LanguageIcon language='de-DE' />
              </MenuItem>
              <MenuItem value='en'>
                <LanguageIcon language='en' />
              </MenuItem>
            </Select>
          </ListItem>
          <PersonalListItem primary={t('startPage')} />
          <ListItem sx={{marginTop: -2}}>
            <Select
              data-testid='route-select'
              size='small'
              autoWidth
              defaultValue={userLandingPage}
              onChange={updateStartPage}
              sx={{width: '100%'}}
              MenuProps={MenuProps}
            >
              <MenuItem value='MONITOR'>{t('monitor', {ns: 'mainMenu'})}</MenuItem>
              <MenuItem value='MODELS'>{t('models', {ns: 'mainMenu'})}</MenuItem>
              <MenuItem value='TASKS'>{t('tasks', {ns: 'mainMenu'})}</MenuItem>
              <MenuItem value='ARCHIVE'>{t('archive', {ns: 'mainMenu'})}</MenuItem>
            </Select>
          </ListItem>
        </List>
      )}
    />
  );
}
