import React from 'react';

import {ErrorAlert} from '~components/common/alert/ErrorAlert';
import {NoContentAlert} from '~components/common/alert/NoContentAlert';
import {NoImagesLayout} from './NoImagesLayout';
import {Spinner} from './Spinner';

export type StatusWrapperComponent = {
  isError?: boolean;
  isLoading?: boolean;
  isEmpty?: boolean;
  isImagesCollection?: boolean;
  emptyText?: string;
  noLoadingSpinner?: boolean;
  creator: () => JSX.Element | null;
};
export function StatusWrapper(props: StatusWrapperComponent): JSX.Element | null {
  if (props.isError) {
    return <ErrorAlert />;
  } else if (props.isLoading) {
    return props.noLoadingSpinner ? null : <Spinner />;
  } else if (props.isEmpty && props.isImagesCollection) {
    return <NoImagesLayout />;
  } else if (props.isEmpty) {
    return <NoContentAlert />;
  } else {
    return props.creator();
  }
}
