import {useTranslation} from 'i18n';
import {useRouter} from 'next/router';
import React from 'react';

import {Alert, StyledAlertProps} from './Alert';

export function ErrorAlert({sx, children}: StyledAlertProps): JSX.Element {
  const router = useRouter();
  const {t} = useTranslation('error');

  const reloadContent = (
    <>
      <span>{t('genericErrorAlert.message.span1')}</span>
      <span>
        <a href='#' onClick={() => router.reload()}>
          {t('genericErrorAlert.message.link')}
        </a>
      </span>
      <span>{t('genericErrorAlert.message.span2')}</span>
    </>
  );

  return (
    <Alert
      severity='error'
      title={children ? undefined : t('genericErrorAlert.title')}
      msgCreator={() => children || reloadContent}
      sx={sx}
    />
  );
}
