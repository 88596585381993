import {Box, Divider, Stack} from '@mui/material';

import {theme} from '~constants/theme';

import {Breadcrumbs} from '~components/header/breadcrumbs/Breadcrumbs';
import {HelpButton} from '~components/header/HelpButton';
import {BackButton} from './BackButton';
import {UserMenu} from './UserMenu';

export interface MainTitleBarProps {
  /**
   * Display a horizontal full-width divider between the breadcrumbs/title and the page content.
   * @default false
   */
  withTitleDivider?: boolean;
  showBackButton?: boolean;
  showHelpMenu?: boolean;
  titleActions?: React.ReactNode;
}

export function MainTitleBar({
  withTitleDivider = false,
  showBackButton = false,
  showHelpMenu = true,
  titleActions,
}: MainTitleBarProps) {
  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{paddingTop: 2, paddingBottom: 2}}>
        <Stack direction='row' alignItems='center' justifyContent='space-between' gap={3}>
          {showBackButton && <BackButton />}
          <Breadcrumbs titleActions={titleActions} />
        </Stack>

        <Box display='flex' alignItems='center' gap={theme.spacing(2)}>
          {showHelpMenu && <HelpButton />}
          <UserMenu />
        </Box>
      </Stack>
      {withTitleDivider && (
        <Divider
          sx={(theme) => ({width: `calc(100% + ${theme.spacing(2 * 3)})`, marginX: -3})}
          orientation={'horizontal'}
        />
      )}
    </>
  );
}
