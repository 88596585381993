import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
  OutlinedTextFieldProps,
} from '@mui/material';
import React, {useId} from 'react';

interface MaddoxTextFieldProps extends Omit<OutlinedInputProps, 'onKeyDown' | 'onKeyUp' | 'variant'> {
  /**
   * Both onKeyDown and onKeyUp need to be overwritten here to match the new event target.
   * The regular OutlinedTextFieldProps expect KeyboardEvent<HTMLDivElement> which this component does not provide as
   * it uses an OutlinedInput directly.
   * */
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => any;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => any;
  formControlProps?: FormControlProps;
  helperText?: OutlinedTextFieldProps['helperText'];
}

export const MaddoxTextField = React.forwardRef<HTMLDivElement, MaddoxTextFieldProps>(function MaddoxTextField(
  {label, error, margin, helperText, formControlProps, ...rest},
  ref,
) {
  const id = useId();

  return (
    <FormControl margin={margin} error={error} {...formControlProps} ref={ref}>
      {Boolean(label) && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <OutlinedInput error={error} {...rest} id={label ? id : undefined} />
      {Boolean(error) && helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
});
