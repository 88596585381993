import {useMutation} from '@tanstack/react-query';
import {useTranslation} from 'i18n';
import {HTTPError} from 'ky';

import {showNotification} from '~components/common/Notification';
import {showApiErrorNotification} from './api';
import {setUserPreferences} from './users';

export function useUserPreferencesMutation() {
  const {t} = useTranslation('settings');

  return useMutation<{landingPage: string}, HTTPError, string>((landingPage) => setUserPreferences(landingPage), {
    onSuccess: () => {
      showNotification({
        message: t('landingPageSuccess'),
        severity: 'success',
      });
    },
    onError: showApiErrorNotification,
  });
}
