import {Typography} from '@mui/material';

import {Link} from '~components/common/Link';
import {Breadcrumb} from '~components/header/breadcrumbs/useBreadcrumbs';

interface BreadCrumbElementProps {
  breadcrumb: Breadcrumb;
}

export function BreadCrumbElement({breadcrumb}: BreadCrumbElementProps): JSX.Element {
  return (
    <Typography variant='h2' component={Link} href={breadcrumb.path || ''}>
      {breadcrumb.name}
    </Typography>
  );
}
