import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import {LoadingButton} from '@mui/lab';
import {Box, Collapse, FormGroup, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import * as Sentry from '@sentry/nextjs';
import {useTranslation} from 'i18n';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {isKyHTTPError} from '~api/api';
import {changePassword} from '~api/users';
import {preventSubmitOnEnter} from '~utils/miscUtils';

import {Alert} from '~components/common/alert/Alert';
import {MaddoxTextField} from '~components/common/MaddoxTextField';
import {showNotification} from '~components/common/Notification';
import {StatusCodes} from 'src/constants/http-status-codes';

type FormData = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ValidationMessage = ({valid, message}: {valid: boolean | undefined; message: string}): JSX.Element => {
  return (
    <Typography
      sx={{
        color: valid ? 'success.dark' : 'maddox.black80',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
      }}
    >
      {valid ? <CheckIcon fontSize='inherit' /> : <RemoveIcon fontSize='inherit' />}
      {message}
    </Typography>
  );
};

interface PasswordTabProps {
  onPasswordChange: () => void;
}

export function PasswordTab({onPasswordChange}: PasswordTabProps): JSX.Element {
  const {t} = useTranslation('user');
  const [showPasswordHints, setShowPasswordHints] = useState(false);

  const {handleSubmit, control, setError, reset, formState, trigger, watch} = useForm<FormData>({
    mode: 'onTouched',
    criteriaMode: 'all',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const {newPassword} = watch();

  useEffect(() => {
    // As the form mode is set to 'onTouched' but we want to update the password hints
    // as soon as the user types something, we need to trigger the validation manually (i.e. override the onTouched behavior)
    if (newPassword) {
      trigger('newPassword');
    }
  }, [trigger, newPassword]);

  const handleChangePassword = async (data: FormData): Promise<void> => {
    const {currentPassword, newPassword} = data;
    try {
      await changePassword(currentPassword, newPassword);
      showNotification({severity: 'success', message: t('changePasswordForm.successfulUpdate')});
      reset({currentPassword: '', newPassword: '', confirmPassword: ''});
      setShowPasswordHints(false);
      onPasswordChange();
    } catch (error: unknown) {
      if (isKyHTTPError(error)) {
        if (error.response.status === StatusCodes.BAD_REQUEST) {
          setError('currentPassword', {
            message: t('changePasswordForm.validation.wrongPassword'),
            type: 'wrongPassword',
          });
        } else {
          Sentry.captureException(error, {extra: {response: error.response}});
          setError('root', {message: t('changePasswordForm.errorGeneric')});
        }
      } else {
        Sentry.captureException(error);
        setError('root', {message: t('changePasswordForm.errorGeneric')});
      }
    }
  };

  return (
    <Stack
      component='form'
      sx={{
        padding: 1,
        '.MuiFormGroup-root': {paddingY: 1},
      }}
      onSubmit={handleSubmit(handleChangePassword)}
      onKeyDown={preventSubmitOnEnter}
    >
      <FormGroup>
        <Controller
          name='currentPassword'
          control={control}
          render={({field, fieldState}) => (
            <MaddoxTextField
              label={t('changePasswordForm.currentPassword')}
              type='password'
              autoComplete='current-password'
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              inputProps={{'data-testid': 'current-password-input'}}
              {...field}
            />
          )}
          rules={{required: {value: true, message: t('changePasswordForm.validation.required')}}}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          name='newPassword'
          control={control}
          rules={{
            required: {value: true, message: t('changePasswordForm.validation.required')},
            minLength: 8,
            validate: {
              number: (value) => !!value.match(/.*[0-9].*/g),
              upperCase: (value) => !!value.match(/.*[A-Z].*/g),
            },
          }}
          render={({field, fieldState, formState}) => (
            <MaddoxTextField
              label={t('changePasswordForm.newPassword')}
              type='password'
              autoComplete='new-password'
              error={formState.submitCount > 0 && fieldState.invalid}
              helperText={fieldState.error?.message}
              inputProps={{'data-testid': 'new-password-input'}}
              onFocus={() => setShowPasswordHints(true)}
              {...field}
            />
          )}
        />
        <Collapse in={showPasswordHints}>
          <Box sx={{marginY: 2}}>
            <ValidationMessage
              valid={formState.dirtyFields.newPassword && !formState.errors.newPassword?.types?.minLength}
              message={t('changePasswordForm.validation.minimumCharacters')}
            />
            <ValidationMessage
              valid={formState.dirtyFields.newPassword && !formState.errors.newPassword?.types?.number}
              message={t('changePasswordForm.validation.containNumber')}
            />
            <ValidationMessage
              valid={formState.dirtyFields.newPassword && !formState.errors.newPassword?.types?.upperCase}
              message={t('changePasswordForm.validation.uppercase')}
            />
          </Box>
        </Collapse>
      </FormGroup>

      <FormGroup>
        <Controller
          name='confirmPassword'
          control={control}
          render={({field, fieldState}) => (
            <MaddoxTextField
              label={t('changePasswordForm.confirmPassword')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              type='password'
              autoComplete='new-password'
              inputProps={{'data-testid': 'confirm-password-input'}}
              {...field}
            />
          )}
          rules={{
            required: {value: true, message: t('changePasswordForm.validation.required')},
            validate: {
              matchesNewPassword: (value, formValues) =>
                formValues.newPassword === value || (t('changePasswordForm.validation.passwordDoesntMatch') as string),
            },
          }}
        />
      </FormGroup>

      {formState.errors.root && <Alert severity='error' msgCreator={() => formState.errors.root?.message} />}

      <LoadingButton
        variant='contained'
        loading={formState.isSubmitting}
        sx={{marginLeft: 'auto', marginTop: 3}}
        disabled={!formState.isValid}
        type='submit'
      >
        {t('save')}
      </LoadingButton>
    </Stack>
  );
}
