import {Logout} from '@mui/icons-material';
import {IconButton, ListItemIcon, Menu, MenuItem} from '@mui/material';
import {useTranslation} from 'i18n';
import React, {useState} from 'react';

import {AuthService} from '~api/auth.service';
import {useJuneTrackEvent} from '~hooks/juneAnalytics.hooks';
import {selectUsername} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {InitialsAvatar} from '~components/common/InitialsAvatar';
import {UserSettings} from '~components/user/UserSettings';
import {SettingsIcon} from 'src/icons';

export function UserMenu() {
  const trackEvent = useJuneTrackEvent();
  const {t} = useTranslation('mainMenu');
  const username: string = useAppSelector(selectUsername) || '';

  const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLElement | null>(null);
  const [openSettings, setOpenSettings] = useState(false);
  const showUserMenu = Boolean(userMenuAnchor);

  function showMenu(event: React.MouseEvent<HTMLElement>) {
    setUserMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setUserMenuAnchor(null);
  }

  function handleOpenSettings() {
    setOpenSettings(true);
  }
  function handleCloseSettings() {
    setOpenSettings(false);
  }

  async function handleLogout() {
    await AuthService.logout();
    trackEvent('logout');
  }

  return (
    <>
      <IconButton
        data-testid='user-menu-button'
        onClick={showMenu}
        size='small'
        aria-label={t('userMenu')}
        aria-controls={showUserMenu ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={showUserMenu ? 'true' : undefined}
        sx={{marginTop: '-4px'}}
      >
        <InitialsAvatar
          name={username}
          sx={[
            {
              backgroundColor: 'secondary.dark',
            },
          ]}
        />
      </IconButton>
      <Menu
        id='account-menu'
        open={showUserMenu}
        anchorEl={userMenuAnchor}
        onClick={closeMenu}
        onClose={closeMenu}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: 1,
            '&:before': {
              // creates an arrow pointing upwards
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem onClick={handleOpenSettings}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          {t('accountSettings')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
      <UserSettings open={openSettings} onClose={handleCloseSettings} />
    </>
  );
}
