import {Box, BoxProps} from '@mui/material';
import React from 'react';

import {sitesTemplatePadding, styled} from 'src/constants/theme';

/**
 * The Layout hierarchy is based on CSS grid and is composed as follows:
  
  Container (defines display: grid)
  +---------|--------------------------------------------+
  |         |                                            |
  | Layout  | LayoutHeader (max-content)                 |
  | Nav     |                                            |
  |         |---------------------------------------------
  |         |                                            |
  |         |                                            |
  |         | LayoutContent (1fr)                        |
  |         |                                            |
  |         |                                            |
  |         |---------------------------------------------
  |         |                                            |
  |         | LayoutFooter (max-content)                 |
  |         |                                            |
  +---------|--------------------------------------------+

 */

export const Container = styled(Box)<BoxProps>(() => ({
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
  display: 'grid',
  gridTemplateRows: 'max-content 1fr max-content',
  gridTemplateColumns: 'max-content 1fr',
  gridTemplateAreas: `
    "Nav Header"
    "Nav Content"
    "Nav Footer"
  `,
}));

const NavBox = React.forwardRef(function NavBox(props, ref) {
  return <Box ref={ref} component='nav' {...props} />;
});

export const LayoutNav = styled(NavBox)<BoxProps>(() => ({
  gridArea: 'Nav',
  height: '100vh',
}));

export const HeaderBox = React.forwardRef(function HeaderBox(props, ref) {
  return <Box ref={ref} component='header' {...props} />;
});

export const LayoutHeader = styled(HeaderBox)<BoxProps>(() => ({
  gridArea: 'Header',
  paddingLeft: sitesTemplatePadding,
  paddingRight: sitesTemplatePadding,
}));

export const FooterBox = React.forwardRef(function NavBox(props, ref) {
  return <Box ref={ref} component='footer' {...props} />;
});

export const LayoutFooter = styled(FooterBox, {shouldForwardProp: (prop) => prop !== 'noBackground'})<
  BoxProps & {noBackground?: boolean}
>(({theme, noBackground}) => ({
  gridArea: 'Footer',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  overflow: 'hidden',
  backgroundColor: noBackground ? undefined : theme.palette.maddox.globalwhite,
  borderTop: noBackground ? 0 : '1px solid',
  borderColor: theme.palette.maddox.black20,
  paddingLeft: sitesTemplatePadding,
  paddingRight: sitesTemplatePadding,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

export const MainBox = React.forwardRef(function MainBox(props, ref) {
  return <Box ref={ref} component='main' {...props} />;
});

export const LayoutContent = styled(MainBox)<BoxProps>(() => ({
  gridArea: 'Content',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: sitesTemplatePadding,
  paddingRight: sitesTemplatePadding,
}));
