import {IconButton, IconButtonProps, Link, ListItemIcon, Menu, MenuItem, Typography} from '@mui/material';
import {useTranslation} from 'i18n';
import {useState} from 'react';

import {theme} from '~constants/theme';

import {InitialsAvatar} from '~components/common/InitialsAvatar';
import {BookOpenIcon} from 'src/icons';

// TODO consider moving it to gitlab?
const KNOWLEDGE_BASE_URL = 'https://support.maddox.ai/knowledge-center';

export function HelpButton({sx, ...other}: IconButtonProps) {
  const {t} = useTranslation('common');

  const [helpMenuAnchor, setHelpMenuAnchor] = useState<HTMLElement | null>(null);
  const showHelpMenu = Boolean(helpMenuAnchor);

  function showMenu(event: React.MouseEvent<HTMLElement>) {
    setHelpMenuAnchor(event.currentTarget);
  }

  function closeMenu() {
    setHelpMenuAnchor(null);
  }

  return (
    <>
      <IconButton
        onClick={showMenu}
        title={t('help')}
        aria-label={t('help')}
        size='small'
        sx={[
          (theme) => ({
            marginTop: '-4px',
            backgroundColor: 'maddox.globalwhite',
            zIndex: showHelpMenu ? theme.zIndex.modal + 1 : undefined,
            '&:hover': {backgroundColor: showHelpMenu ? 'maddox.globalwhite' : undefined},
            borderRadius: theme.spacing(14),
            gap: theme.spacing(0.5),
            height: theme.spacing(5),
            paddingX: theme.spacing(1),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        data-testid='help-button'
        {...other}
      >
        <InitialsAvatar
          backgroundColor='inherit'
          name='?'
          sx={[
            {
              color: 'white',
              backgroundColor: 'secondary.dark',
              border: '1.5px solid',
              borderColor: 'secondary.dark',
              width: 24,
              height: 24,
              padding: theme.spacing(0.5),
            },
          ]}
        />
        <Typography variant='body2' color='secondary.dark'>
          {t('help')}
        </Typography>
      </IconButton>

      <Menu
        id='help-menu'
        open={showHelpMenu}
        anchorEl={helpMenuAnchor}
        onClick={closeMenu}
        onClose={closeMenu}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: 1,
            '&:before': {
              // creates an arrow pointing upwards
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
            },
          },
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <BookOpenIcon />
          </ListItemIcon>
          <Link color={theme.palette.common.black} underline='none' href={KNOWLEDGE_BASE_URL} target='_blank'>
            {t('knowledgeCenter')}
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}
