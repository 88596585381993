import {Box, DialogProps, DialogTitle, Tab, Tabs} from '@mui/material';
import {useTranslation} from 'i18n';
import {useState} from 'react';

import {ClosableDialog} from '~components/common/ClosableDialog';
import {AccountTab} from './AccountTab';
import {PasswordTab} from './PasswordTab';
import {PersonalTab} from './PersonalTab';

export interface UserSettingsProps extends Omit<DialogProps, 'title'> {
  open: boolean;
  onClose?: () => void;
}

export function UserSettings({open, onClose, ...rest}: UserSettingsProps) {
  const {t} = useTranslation('user');
  const [activeTab, setActiveTab] = useState('account');

  const handleChange = (_event: React.SyntheticEvent, tabName: string) => {
    setActiveTab(tabName);
  };

  const handlePasswordChange = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ClosableDialog fixedToTop hasTabs open={open} onClose={onClose} {...rest}>
      <DialogTitle sx={{paddingBottom: 1}}>{t('settings')}</DialogTitle>
      <Box sx={{bgcolor: 'background.paper', width: 450}}>
        <Tabs sx={{paddingX: 3}} value={activeTab} onChange={handleChange}>
          <Tab value='account' label={t('account')} />
          <Tab value='personal' label={t('personal')} />
          <Tab value='password' label={t('password')} />
        </Tabs>
        <Box sx={{paddingX: 3, paddingBottom: 2}}>
          {activeTab === 'account' && <AccountTab />}
          {activeTab === 'personal' && <PersonalTab />}
          {activeTab === 'password' && <PasswordTab onPasswordChange={handlePasswordChange} />}
        </Box>
      </Box>
    </ClosableDialog>
  );
}
